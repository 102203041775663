//Variables to replace
const poolId = 'us-east-1_l7qeOdljH'
const clientId = '799849cu2vvahlflcn0pf4t268'
const api = 'https://ey8az5m6q6.execute-api.us-east-1.amazonaws.com/Prod'

const Config = {
    UserPoolId: poolId,
    AppClientId: clientId,
    ApiURL: api + '/notes'
}

export default Config